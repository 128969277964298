///* ----------- Default Styling Start --------------- */

$primary-fontFamily: "Montserrat", sans-serif;
$primary-fontWeight: 400;
$primary-lineHeight: 1.4;
$primary-fontColor: #6e6b7b;
$primary-color: #2056a5;
$primary-hover: #1b427c;
$primary-bgColor: #fafafa;
$color-black: #000000;
$color-white: #ffffff;

/// Viewport
$desktop_xxxl: 1920px;
$desktop_xxl: 1650px;
$desktop_xl: 1440px;
$laptop: 1366px;
$desktop: 1280px;
$desktopSmall: 1024px;
$tabletSmall: 768px;
$mobileBig: 767px;
$mobile: 480px;
$mobileSmall: 360px;

///* ----------- Default Styling End ----------------- */

.signUpPage {
    $signUpPage: ".signUpPage";

    #{$signUpPage}__main {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        // @media (max-width: $desktop - 1) {
        // 	flex-direction: column;
        // }
        @media (max-width: $desktopSmall - 1px) {
            flex-direction: column;
        }

        #{$signUpPage}__left {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            width: 40%;
            height: 100vh;
            background-color: #fbfaff;
            padding: 4%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 0;
            overflow: auto;

            @media (max-width: $desktop - 1) {
                width: 100%;
                min-height: 50vh;
            }

            @media (max-width: $mobileBig) {
                padding: 4rem 2rem 2rem;
            }

            #{$signUpPage}__backButton {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                position: absolute;
                top: 1rem;
                left: 4%;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 1rem;
                        color: #191a1a;
                        margin-right: 0.25rem;
                    }

                    span {
                        display: inline-block;
                        font-size: 1rem;
                        font-weight: 600;
                        line-height: 1;
                        color: #191a1a;
                    }
                }
            }

            #{$signUpPage}__slider {
                width: 100%;
                // background-color: $primary-color;
                border-radius: 0.5rem;
                padding: 1rem 0 0;

                @media (max-width: $desktop - 1) {
                    width: 60%;
                }

                @media (max-width: $mobileBig) {
                    width: 100%;
                }

                * {
                    outline: none !important;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #191a1a;
                }

                &--forgetPassword {
                    .slick-slider {
                        padding: 0 !important;
                    }
                }

                .slick-slider {
                    padding: 0 0 1rem;
                }

                #{$signUpPage}__box {
                    // display: flex;
                    // align-items: flex-start;

                    padding: 0 1rem 1rem;

                    h4 {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.4;
                        color: white;
                        margin: 0 0 0.5rem;
                        text-transform: uppercase;
                    }

                    h2 {
                        font-size: 1.6rem;
                        font-weight: 800;
                        line-height: 1.4;
                        color: white;
                        margin: 0 0 0.5rem;
                    }

                    p {
                        font-size: 0.65rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: white;
                        margin: 0;
                    }

                    figure {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        margin: 0 auto;
                        width: 58.4%;

                        img {
                            width: auto;
                        }
                    }
                }

                .slick-dots {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;

                    // bottom: 1.25rem;
                    bottom: 0;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 0.6rem;
                        height: 0.6rem;
                        margin: 0;
                        margin-right: 0.5rem;

                        &:last-child {
                            margin: 0;
                        }

                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 0.6rem;
                            height: 0.6rem;
                            padding: 0;
                            margin: 0;
                            border-radius: 0.6rem;
                            background-color: rgba($color-black, 0.1);

                            &::before {
                                display: none;
                            }
                        }

                        &.slick-active {
                            width: 1.6rem;

                            button {
                                width: 1.6rem;
                                background: #f38b3e;
                            }
                        }
                    }
                }
            }
        }

        #{$signUpPage}__right {
            display: flex;
            flex-direction: column;

            width: 60%;
            // height: 100vh;
            background-color: #f9f9f9;
            box-shadow: 0 0 1rem 0.25rem rgba(#2b1c03, 0.1);
            padding: 4% calc(4% - 1.5rem);
            // position: fixed;
            // top: 0;
            // right: 0;
            z-index: 1;
            // overflow: auto;
            border-radius: 0.5rem;

            &.resetPass {
                width: 34%;
                @media (max-width: $desktop - 1) {
                    width: 90%;
                    min-height: 50vh;
                }
                .signUpPage__heading {
                    figure {
                        width: 30%;
                    }
                }

                .signUpPage__form {
                    .linkExp {
                        color: #e70e0e;
                        border: #e70e0e dotted 1px;
                        text-align: center;
                        padding: 6px 0;
                        background: #f7e9e9;
                    }
                }
            }

            @media (max-width: $desktop - 1) {
                width: 90%;
                min-height: 50vh;
            }

            @media (max-width: $mobileBig) {
                padding: 1rem;
            }

            #{$signUpPage}__heading {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                text-align: center;
                width: 100%;
                margin: 0 0 2rem;

                &:last-child {
                    margin: 0;
                }

                figure {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 25%;
                    max-width: 10rem;
                    margin: 0 auto;

                    img {
                        width: 100%;
                    }
                }

                h1 {
                    font-size: 1.7rem;
                    font-weight: 700;
                    line-height: 1.2;
                    color: #191a1a;
                    margin: 1rem 0 0;
                }

                h4 {
                    font-size: 0.9rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #191a1a;
                    margin: 1rem 0 0;

                    &.thankyouHeading {
                        display: block;

                        span {
                            color: #84bd00;
                        }

                        span,
                        strong {
                            display: block;
                        }
                    }
                }

                p {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    margin: 1rem 0 0;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 0.8rem;
                        font-weight: 700;
                        line-height: 1;
                        color: #0f61dc;
                        background-color: rgba(#2056a5, 0.12);
                        border-radius: 1.5rem;
                        padding: 0.75rem 3rem;

                        i {
                            font-size: 1rem;
                            margin-right: 0.5rem;
                        }
                    }

                    a {
                        display: inline-block;
                        padding: 0.6rem 3rem;

                        i {
                            margin-right: 0.5rem;
                        }
                    }

                    button {
                        display: inline-block;
                        padding: 0.6rem 3rem;

                        i {
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            #{$signUpPage}__form {
                display: flex;
                flex-direction: column;

                width: 100%;

                @media (max-width: $desktop - 1) {
                    width: 60%;
                    margin: 0 auto;
                }

                @media (max-width: $mobileBig) {
                    width: 100%;
                }

                #{$signUpPage}__formSection {
                    display: flex;
                    flex-direction: column;

                    padding-left: 0.75rem;
                    padding-right: 0.75rem;

                    > .row {
                        > [class*="col-"] {
                            &:last-child {
                                .form-group {
                                    margin: 0;
                                }
                            }
                        }
                    }

                    .form-group {
                        margin: 0 0 1rem;
                        position: relative;

                        .css-qbdosj-Input {
                            margin: 0;
                            padding: 0;
                        }
                        .css-13cymwt-control {
                            border-radius: 0.375rem;
                            min-height: 27px;

                            .css-1xc3v61-indicatorContainer {
                                padding: 0.215rem !important;
                            }
                        }
                        .css-1jqq78o-placeholder {
                            margin: 0;
                            font-size: 0.8rem;
                            color: #191a1a;
                        }
                        .css-1fdsijx-ValueContainer {
                            padding: 0.4rem 0.9rem !important;
                            font-size: 1rem !important;
                        }
                        @media screen and (min-width: $tabletSmall) and (max-width: $desktopSmall) {
                            .css-1fdsijx-ValueContainer {
                                padding: 0.4rem 0.9rem !important;
                                font-size: 0.8rem !important;
                            }
                        }
                        .css-1dimb5e-singleValue {
                            font-size: 0.8rem;
                        }
                        .css-t3ipsp-control {
                            border-radius: 0.375rem;
                            min-height: 27px;

                            .css-15lsz6c-indicatorContainer {
                                padding: 0.215rem !important;
                            }
                        }

                        &.withIcon {
                            .form-control {
                                padding: 0.6rem 0.9rem;
                                border-radius: 0.375rem;
                                padding-right: 2.5rem;
                            }

                            .passwordToggle {
                                display: block;
                                position: absolute;
                                top: 1.2rem;
                                right: 0.75rem;
                                width: 1.25rem;
                                transform: translateY(-50%);

                                img {
                                    width: 100%;
                                }
                            }

                            &--forgetPassword {
                                .form-control {
                                    border-radius: 0.375rem;
                                    padding: 0.6rem 0.9rem 0.6rem 0.6rem !important;
                                    // padding-left: 2.5rem !important;
                                }

                                .passwordToggle {
                                    display: block;
                                    position: absolute;
                                    top: 0.7rem;
                                    left: 0.75rem;
                                    width: 1.25rem;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        label {
                            font-size: 0.8rem;
                            font-weight: 600;
                            line-height: 1.5;
                            color: #191a1a;
                            margin: 0 0 0.5rem;
                        }

                        .form-control {
                            border-radius: 0.375rem;
                            font-size: 0.8rem;
                            font-weight: 400;
                            line-height: 1.5;
                            letter-spacing: 0.025rem;
                            color: #191a1a;
                            padding: 0.5rem 0.75rem;
                            min-height: 2.25rem;
                            background-color: $primary-bgColor;

                            &::-webkit-input-placeholder {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                letter-spacing: 0.025rem;
                                color: #191a1a;
                            }

                            &::-moz-placeholder {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                letter-spacing: 0.025rem;
                                color: #191a1a;
                            }

                            &:-ms-input-placeholder {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                letter-spacing: 0.025rem;
                                color: #191a1a;
                            }

                            &:-moz-placeholder {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                letter-spacing: 0.025rem;
                                color: #191a1a;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        > p {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            font-size: 0.8rem;
                            font-weight: 600;
                            line-height: 1.5;
                            color: #191a1a;
                            margin: 0;

                            &.text-end {
                                justify-content: flex-end;
                            }

                            input {
                                margin: 0 0.5rem 0 0;
                            }

                            a {
                                display: inline-block;
                                font-size: 0.8rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $primary-color;
                                margin-left: 0.25rem;
                            }
                        }

                        // .textValidatorError {
                        // 	display: flex;
                        // 	flex-wrap: wrap;

                        // 	margin-top: 0.5rem;

                        // 	p {
                        // 		font-size: 0.8rem;
                        // 		font-weight: 700;
                        // 		line-height: 1.5;
                        // 		color: #191a1a;
                        // 		margin: 0 0 0.5rem;
                        // 		width: 100%;
                        // 	}

                        // 	ul {
                        // 		display: flex;
                        // 		flex-wrap: wrap;

                        // 		margin: 0;
                        // 		padding: 0;

                        // 		li {
                        // 			display: block;
                        // 			font-size: 0.7rem;
                        // 			font-weight: 700;
                        // 			line-height: 1.5;
                        // 			color: #8b8c8c;
                        // 			margin: 0 0 0.5rem;

                        // 			width: 50%;

                        // 			&:nth-last-child(1),
                        // 			&:nth-last-child(2) {
                        // 				margin-bottom: 0;
                        // 			}

                        // 			span {
                        // 				display: block;

                        // 				&.passwordAdded {
                        // 					color: #50bc14;
                        // 				}
                        // 			}
                        // 		}
                        // 	}
                        // }

                        .signup_checklabel {
                            display: flex;
                            align-items: center;

                            input {
                                margin-right: 0.5rem;
                                width: 20px;
                                height: 20px;
                            }

                            a {
                                margin-left: 5px;

                                &:hover {
                                    text-decoration: underline !important;
                                }
                            }

                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
