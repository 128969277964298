.dashboard__customerViewCard {
    .query_builder_row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .query_builder_box {
            width: 50%;

            .person {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                p {
                    margin-right: 1rem;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            margin-bottom: 1rem;

            .query_builder_box {
                width: 100%;
            }
        }
    }
}

@import "react-querybuilder/dist/query-builder.scss";

.queryBuilder {
    .ant-input {
        width: auto;
    }
    .ant-btn.css-dev-only-do-not-override-nllxry.ant-btn-primary.ruleGroup-addGroup span::after {
        content: " Rule";
    }
    select,
    input {
        padding: 4px 10px;
        border: 1px solid #282828;
        border-radius: 4px;
    }
    button {
        padding: 4px 10px;
        background-color: #d9eeff;
        color: #282828;
        border: 1px solid #282828;
        border-radius: 4px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 600px) {
    .ruleGroup .ruleGroup-header,
    .ruleGroup .rule {
        flex-wrap: wrap;
    }
}
