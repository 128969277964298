@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

///* ----------- Default Styling Start --------------- */

$primary-fontFamily: "Montserrat", sans-serif;
//$primary-fontFamily: 'Inter', sans-serif;
$primary-fontWeight: 400;
$primary-lineHeight: 1.4;
$primary-fontColor: #6e6b7b;
$primary-color: #2056a5;
$primary-hover: #1b427c;
$primary-bgColor: #fafafa;
$color-black: #000000;
$color-white: #ffffff;

/// Viewport
$desktop_xxxl: 1920px;
$desktop_xxl: 1650px;
$desktop_xl: 1440px;
$laptop: 1366px;
$desktop: 1280px;
$desktopSmall: 1024px;
$tabletSmall: 768px;
$mobileBig: 767px;
$mobile: 480px;
$mobileSmall: 360px;

///* ----------- Default Styling End ----------------- */

* {
    margin: 0;
    padding: 0;
    text-decoration: none !important;
    outline: none !important;

    &::-webkit-scrollbar {
        width: 0.25rem !important;
        height: 0.25rem !important;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        outline: none;
    }

    &:focus {
        box-shadow: none !important;
    }
}

html {
    font-size: 20px;

    @media (max-width: $desktop_xl - 1) {
        font-size: 18px;
    }

    @media (max-width: $laptop - 1) {
        font-size: 16px;
    }

    // @media (max-width: $desktop - 1) {
    // 	font-size: 14px;
    // }

    @media (max-width: $desktopSmall - 1) {
        font-size: 12px;
    }

    // @media (max-width: $mobileBig) {
    // 	font-size: 10px;
    // }

    @media (max-width: $mobile - 1) {
    }
}

body {
    font-size: 0.8rem;
    font-family: $primary-fontFamily;
    font-weight: $primary-fontWeight;
    line-height: $primary-lineHeight;
    color: $primary-fontColor;
}

.badge {
    &.bg-primary {
        background-color: $primary-color !important;
    }

    &.bg-success {
        background-color: #71d875 !important;
    }

    &.bg-warning {
        background-color: #f99240 !important;
    }
}

.mt40 {
    margin-top: 40px;
}

.container-fluid,
.container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.container {
    width: 96%;
    max-width: 1440px;
}

.row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

[class*="col-"] {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.table {
    white-space: nowrap !important;

    thead,
    tbody,
    tfoot {
        th,
        td {
            padding: 0.6rem !important;
            text-align: center;
        }
    }
}
#customerGroup {
    white-space: nowrap !important;

    thead,
    tbody,
    tfoot {
        th,
        td {
            padding: 0.6rem !important;
            text-align: left !important;
        }
    }
}
.form-group {
    margin: 0 0 1rem;
}

.form-control,
.css-13cymwt-control,
.css-1s2u09g-control {
    border-radius: 0.375rem;
    background-color: $primary-bgColor !important;
    border-color: #ccc;

    &:hover {
        border-color: #86b7fe !important;
    }
}

input[type="text"].form-control,
input[type="date"].form-control,
textarea.form-control,
.css-319lph-ValueContainer,
.css-1fdsijx-ValueContainer,
.css-1d8n9bt {
    font-size: 1rem;
    padding: 0.45rem 0.9rem !important;
}

input[type="date"].form-control {
    font-size: 0.8rem;
    padding: 0.375rem 0.75rem !important;
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 0.75rem !important;
}

.modal-header {
    background-color: $primary-color;

    .modal-title,
    .btn-close {
        color: $color-white;
        opacity: 1;
    }
}

// .modal-dialog {
// 	max-width: 60rem;
// }

.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    border-radius: 0.3rem;
    transition: all 0.5s ease;

    &.btn-sm {
        padding: 0.15rem 0.45rem;
        font-size: 0.6rem;
    }

    &.btn-lg {
        padding: 0.6rem 1.8rem;
        font-size: 1rem;
    }

    &.btn-xl {
        padding: 0.8rem 2.4rem;
        font-size: 1.2rem;
    }

    &.btn-primary {
        background-color: $primary-color;
        color: white;
        border: 1px solid $primary-color;

        &:hover {
            background-color: $primary-hover;
            color: white;
        }
    }

    &.btn-outline-primary {
        color: $primary-color;
        background-color: white;
        border: 1px solid $primary-color;

        &:hover {
            background-color: $primary-color;
            color: white;
        }
    }
}

textarea.form-control {
    min-height: 5rem !important;
    resize: none !important;
}

.form-control {
    font-size: 0.8rem;
}

img {
    max-width: 100%;
}

.characterLimitProperty {
    font-weight: 400;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textValidatorError {
    display: flex;
    flex-wrap: wrap;

    margin-top: 0.25rem;
    color: red;

    p {
        display: flex;
        flex-direction: column;

        font-size: 0.6rem;
        font-weight: 700;
        line-height: 1.5;
        color: #8b8c8c;
        margin: 0 0 0.5rem;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        span {
            display: block;

            &.passwordAdded {
                color: #50bc14;
            }
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        margin: 0;
        padding: 0;

        li {
            display: flex;
            flex-direction: column;

            font-size: 0.6rem;
            font-weight: 700;
            line-height: 1.5;
            color: #8b8c8c;
            margin: 0 0.5rem 0.5rem 0;

            // width: 50%;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            span {
                display: block;

                &.passwordAdded {
                    color: #50bc14;
                }
            }
        }
    }
}

.selectArrow {
    // background: transparent url("../Image/arrowDownIcon.jpg") no-repeat right 0.5rem center / 0.6rem auto;
    // padding-right: 1.2rem !important;

    @media (max-width: $mobileBig) {
        background-position: right 0.25rem center;
    }
}

.errorMessage {
    color: red !important;
}

.successMessage {
    color: #50bc14 !important;
}

.dashboard__transactionsModal {
    display: flex;
    flex-direction: column;

    .dashboard__transactionsModalProfile {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .dashboard__transactionsModalDetails {
            display: flex;
            flex-direction: column;

            &:nth-child(1) {
                width: 100%;

                .dashboard__transactionsModalBottom {
                    display: flex;
                    flex-direction: column;

                    ul {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        padding: 0;
                        margin: 0 0 0.5rem;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        li {
                            width: 50%;

                            &:nth-last-child(2),
                            &:nth-last-child(1) {
                                margin: 0;
                            }

                            &:nth-child(1) {
                                // margin: 0;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                width: 50%;
                padding-right: 1rem;
                border-right: 0.05rem solid #dee2e6;
                margin-top: 1.5rem;
            }

            &:nth-child(3) {
                width: 50%;
                padding-left: 1rem;
                margin-top: 1.5rem;
            }

            .dashboard__transactionsModalTop {
                display: flex;
                flex-direction: column;

                margin: 0 0 1rem;

                h2 {
                    font-size: 0.9rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: #282828;
                    margin: 0;
                }
            }

            .dashboard__transactionsModalBottom {
                display: flex;
                flex-direction: column;

                ul {
                    display: flex;
                    flex-direction: column;

                    padding: 0 0 0.5rem;
                    border-bottom: 0.05rem solid #dee2e6;
                    margin: 0 0 0.5rem;

                    &:last-child {
                        padding: 0;
                        border-bottom: none;
                        margin: 0;
                    }

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        font-size: 0.7rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: #282828;
                        margin: 0 0 0.7rem;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        span {
                            display: inline-block;

                            &:last-child {
                                font-weight: 400;
                                max-width: calc(100% - 8rem);
                            }

                            &:first-child {
                                font-weight: 600;
                                margin-right: 0.5rem;
                            }
                        }

                        a {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

.mainPreloaderMain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 99999;

    .mainPreloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 4rem;
        z-index: 99999;

        div {
            animation: mainPreloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 2rem 2rem;

            &:after {
                content: " ";
                display: block;
                position: absolute;
                width: 0.35rem;
                height: 0.35rem;
                border-radius: 50%;
                background: $primary-color;
                margin: -0.2rem 0 0 -0.2rem;
            }

            &:nth-child(1) {
                animation-delay: -0.036s;

                &:after {
                    top: 3.15rem;
                    left: 3.15rem;
                }
            }

            &:nth-child(2) {
                animation-delay: -0.072s;

                &:after {
                    top: 3.4rem;
                    left: 2.8rem;
                }
            }

            &:nth-child(3) {
                animation-delay: -0.108s;

                &:after {
                    top: 3.55rem;
                    left: 2.4rem;
                }
            }

            &:nth-child(4) {
                animation-delay: -0.144s;

                &:after {
                    top: 3.6rem;
                    left: 2rem;
                }
            }

            &:nth-child(5) {
                animation-delay: -0.18s;

                &:after {
                    top: 3.55rem;
                    left: 1.6rem;
                }
            }

            &:nth-child(6) {
                animation-delay: -0.216s;

                &:after {
                    top: 3.4rem;
                    left: 1.2rem;
                }
            }

            &:nth-child(7) {
                animation-delay: -0.252s;

                &:after {
                    top: 3.15rem;
                    left: 0.85rem;
                }
            }

            &:nth-child(8) {
                animation-delay: -0.288s;

                &:after {
                    top: 2.8rem;
                    left: 0.6rem;
                }
            }
        }
    }
}

@keyframes mainPreloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes progressbarSlider {
    0% {
        left: -100%;
    }

    50% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}

.zoom_in {
    transform: scale(1.3) !important;
}

.earnedValueBtn {
    border-radius: 30px;
    background: $primary-color;
    color: #fff;
    // min-width: 60px;
    padding: 5px 0;

    span {
        padding: 0 20px;
    }

    &:hover {
        background: $primary-hover !important;
    }
}

.font-bold {
    font-weight: 700;
}