///* ----------- Default Styling Start --------------- */

$primary-fontFamily: "Montserrat", sans-serif;
$primary-fontWeight: 400;
$primary-lineHeight: 1.4;
$primary-fontColor: #6e6b7b;
$primary-color: #2056a5;
$primary-hover: #1b427c;
$primary-bgColor: #fafafa;
$color-black: #000000;
$color-white: #ffffff;

/// Viewport
$desktop_xxxl: 1920px;
$desktop_xxl: 1650px;
$desktop_xl: 1440px;
$laptop: 1366px;
$desktop: 1280px;
$desktopSmall: 1024px;
$tabletSmall: 768px;
$mobileBig: 767px;
$mobile: 480px;
$mobileSmall: 360px;

/// Progressbar Color & Speed
$progressbar-color1: #4071eb;
$progressbar-color2: #56cfeb;
$progressbar-speed: 2.5s;

///* ----------- Default Styling End ----------------- */

.modal__earningrules {
    $modal__earningrules: ".modal__earningrules";
	display: flex;
	flex-direction: row;

    .dashboard__customFormBox{display: flex;flex-direction: column;margin: 0 0 1.5rem;}
    .dashboard__customFormCard{display: flex;flex-direction: column;background-color: #fafafa;border: 0.1rem solid #d2d3d3;border-radius: 0.375rem;}
    .dashboard__top{display: flex;align-items: center;justify-content: space-between;margin: -0.05rem -0.05rem 0;}
    .dashboard__top .dashboard__left{display: flex;align-items: center;justify-content: flex-start;}
    .dashboard__left button,.dashboard__right button{font-size: 0.7rem;font-weight: 700;line-height: 1.5;padding: 0.5rem 2rem;border-radius: 0;text-transform: uppercase;margin-left: -0.05rem;}
    .dashboard__left button:first-child{border-top-left-radius: 0.375rem;}
    .dashboard__right button:last-child{border-top-right-radius: 0.375rem;}
    .dashboard__right button i{margin-right: 0.25rem;}
    .dashboard__top .dashboard__right{display: flex;align-items: center;justify-content: flex-end;}
    .dashboard__middle{display: flex;flex-direction: column;padding: 1rem;}
    .button-section{display: flex;align-items: center;justify-content: flex-end;}
    .button-section button:first-child{margin-right: 10px;}
}

.dashboard__transactionsModal{
    .customForm{
        .form-group.withSwitch{
            display: flex;
            flex-direction: row;
            margin: 0;
            margin-left: -0.75rem;
            margin-bottom: 0.75rem;

            label{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;

                .react-switch {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.dashboard__headingBackLink2{
    a{display: inline-block;border-radius: 30px;width: 80px;height: 30px;background-color: #FFF;line-height: 30px;text-align: center;color: #000;margin-bottom: 10px;}
    img{width: 20px;margin-left: -2px;margin-right: 5px;margin-top: -2px;}
}
