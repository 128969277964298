$tour_primary_color: #0d6efd;

.tour_modal {
    .modal-dialog {
        max-width: 500px;
        top: 20%;

        @media only screen and (max-width: 768px) {
            top: 10%;
        }
        .modal-content {
            background: hsla(203, 80%, 96%, 1);
            background: linear-gradient(90deg, hsla(203, 80%, 96%, 1) 0%, hsla(202, 100%, 98%, 1) 30%, hsla(0, 0%, 100%, 1) 100%);
            background: -moz-linear-gradient(90deg, hsla(203, 80%, 96%, 1) 0%, hsla(202, 100%, 98%, 1) 30%, hsla(0, 0%, 100%, 1) 100%);
            background: -webkit-linear-gradient(90deg, hsla(203, 80%, 96%, 1) 0%, hsla(202, 100%, 98%, 1) 30%, hsla(0, 0%, 100%, 1) 100%);
        }
    }
}

.custom_header {
    background-color: transparent;
    border-color: transparent;
}

.dashboard__transactionsModal {
    h3.guides {
        text-align: center;
        color: $tour_primary_color;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    h3.guides_nv {
        text-align: center;
        color: $tour_primary_color;
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    h5 {
        color: #000;
        text-align: center;
        font-weight: 700;

        &.space_h5 {
            margin-bottom: 1.5rem;
        }
    }

    p {
        width: 90%;
        margin: 0 auto;
        color: #000;
        margin-bottom: 1.5rem;
        text-align: center;

        &.lh16 {
            line-height: 1.6;
        }
    }

    p.p_nv {
        text-align: center;
        font-size: 16px;
        padding: 0 1rem;
        color: #000;
    }

    .start_tour_btn {
        display: flex;
        justify-content: center;

        button {
            text-transform: uppercase;
        }
    }
}

.tour_mainbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 768px) {
        flex-direction: row;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tour_box {
        width: calc(100% / 3 - 15px);
        margin-right: 15px;
        border-radius: 1rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        text-align: center;
        border: 1px solid #fff;
        align-self: stretch;

        &:hover {
            background-color: #e9f3ff;
            border-radius: 1rem;
            border: 1px solid $tour_primary_color;
        }

        @media only screen and (max-width: 600px) {
            width: calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            padding: 1.5rem;
            display: flex;
            flex-direction: column;

            p {
                font-size: 1rem;
                margin-bottom: 0;
                color: $tour_primary_color;
                font-weight: 500;
            }
        }
    }

    &.tour_mainbox2 {
        justify-content: space-evenly;

        .tour_box {
            width: calc(100% / 2 - 15px);
            margin-right: 15px;
            border-radius: 0.2rem;
            //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            text-align: center;
            border: 1px solid $tour_primary_color;
            align-self: stretch;
            background-color: $tour_primary_color;

            @media only screen and (max-width: 600px) {
                width: calc(50% - 10px);
                margin-right: 10px;
                margin-bottom: 1rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                padding: 0.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                figure {
                    margin-bottom: 0;
                    width: 25%;

                    img {
                        width: 30px;
                        filter: brightness(0) invert(1);
                    }
                }

                p {
                    font-size: 0.8rem;
                    margin-bottom: 0;
                    color: #fff;
                    font-weight: 500;
                }
            }

            &:hover {
                background-color: #e9f3ff;

                a {
                    img {
                        filter: none;
                    }
                    p {
                        color: $tour_primary_color;
                    }
                }
            }

            &:last-child {
                margin-right: 0;

                border: 1px solid $tour_primary_color;
                background-color: transparent;

                a {
                    figure {
                        img {
                            filter: none;
                        }
                    }
                    p {
                        color: $tour_primary_color;
                    }
                }

                &:hover {
                    border: 1px solid $tour_primary_color;
                    background-color: $tour_primary_color;

                    figure {
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                    p {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.stour_mainbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 768px) {
        flex-direction: row;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }

    .stour_box {
        width: calc(50% - 10px);
        border-radius: 0.5rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 1px solid $tour_primary_color;
        justify-content: center;
        display: flex;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: #e9f3ff;
            border-radius: 0.5rem;
            border: 1px solid $tour_primary_color;
        }

        &.startt_tour_btn {
            background-color: $tour_primary_color;

            button {
                figure {
                    img {
                        filter: brightness(0) invert(1);
                        width: 30px;
                    }
                }

                p {
                    color: #fff;
                }
            }

            &:last-child {
                margin-right: 0;
                background-color: transparent;
                font-weight: 600;
                border-color: $tour_primary_color;

                p {
                    color: #0d6efd;
                }

                figure {
                    img {
                        filter: none;
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            width: calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }

        button {
            padding: 0.5rem;
            display: flex;
            flex-direction: row;
            background-color: transparent;
            border: none;

            &:hover {
                background-color: transparent;
            }

            figure {
                margin-bottom: 0;
                margin-left: 0.5rem;
            }

            p {
                font-size: 0.8rem;
                margin-bottom: 0;
                color: $tour_primary_color;
                font-weight: 500;
            }
        }
    }
}

.comm_email_sample {
    background-color: #fafafa;
    border: 0.1rem solid #d2d3d3;
    border-radius: 0.375rem;
    padding: 1rem;

    h2 {
        color: #000;
        font-size: 28px;
        margin-bottom: 1.5rem;
    }

    h3 {
        color: $tour_primary_color;
    }
    .comm_email_sample_box {
        width: 600px;
        margin: 0 auto;
        padding: 40px 20px;
        border: 1px solid #000;
        border-radius: 0;
        text-align: center;

        img {
            width: 100px;
        }

        .comm_email_sample_boxed {
            width: 50%;
            margin: 0 auto;
            padding: 1rem;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin-bottom: 1rem;
            margin-top: 1rem;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            h3 {
                color: $tour_primary_color;
                font-size: 60px;
                letter-spacing: 5px;
                font-weight: 700;
            }

            h4 {
                text-transform: uppercase;
                letter-spacing: 4px;
                font-size: 18px;
                color: #000;
            }

            p {
                margin-bottom: 0;
            }
        }

        p {
            color: #000;
            margin-bottom: 0;
        }
    }
}

.journey___box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .logo___box {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .user___box {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.integration___heading {
    text-align: center;

    h4 {
        color: #000;
        font-weight: 600;
    }

    p {
        color: #000;
    }
}

.integration___row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;

    @media only screen and (max-width: 550px) {
        justify-content: center;
    }

    .integration___box {
        width: calc(100% / 4 - 10px);
        margin-right: 10px;
        padding: 5px;
        background-color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin-bottom: 10px;

        &:nth-child(4n) {
            margin-right: 0;
        }

        @media only screen and (max-width: 550px) {
            width: calc(100% / 3 - 10px);
            margin-right: 10px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

.cj_home_one {
    padding: 0 1rem;
    width: 1100px;
    margin: 0 auto;

    //img{width: 100% !important;}

    .cj_home_one_body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cj_home_one_body_left_with_button {
            position: relative;

            .rewards_button {
                position: absolute;
                top: 25px;
                right: 15px;
                width: 125px;
            }
        }

        .cj_home_one_body_right {
            img {
                width: 100%;
            }
        }
    }

    .cj_home_one_big_body {
        position: relative;

        .upload_bill_button {
            position: absolute;
            right: 2px;
            width: 55px;
            bottom: 11px;
        }

        .redemption___box {
            position: absolute;
            bottom: 0;
        }
    }

    .cj_home_one_redemeed {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
