.modal-body {
    &.payment_status_modal {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            margin-bottom: 1rem;
        }

        h4 {
            color: #000;
        }
    }
}

.payment_status{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    background-color: #FFF;
    border-radius: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    color: #000;

    h2{
        color: #000;
    }

    p{
        margin-bottom: 0;
    }

    img{
        margin-bottom: 10px;
    }

    &.pfailed{

        p{
            margin-bottom: 1rem;
        }

        ul{
            li{
                list-style: none;
                position: relative;
                margin-bottom: 5px;

                &:before{
                    content: '';
                    width: 7px;
                    height: 15px;
                    display: inline-block;
                    border: none;
                    border-bottom: 2px solid #6e6b7b;
                    border-right: 2px solid #6e6b7b;
                    position: absolute;
                    transform: rotate(45deg);
                    margin-left: -20px;
                }
            }
        }
    }

    
}

@media only screen and (min-width: 700px){
    .payment_status{
        width: 600px;
        margin: 0 auto;
    }
}


.oacta{
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        li{
            list-style: none;
            width: calc(100%/2 - 10px);
            margin-right: 10px;

            &:last-child{
                margin-right: 0;
            }

            a{
                background-color: rgb(10, 110, 190);
                color: #FFF;
                padding: 5px 10px;
                display: inline-block;
                border-radius: 5px;
                font-weight: 600;
            }
        }
    }
}
