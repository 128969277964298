$white: #ffffff;
$grey-0: #fff;
$grey-25: #f1f1f1;
$grey-50: #f8f9fa;
$grey-100: #f1f3f4;
$grey-200: #e8eaed;
$grey-300: #dadce0;
$grey-400: #bdc1c6;
$grey-500: #9aa0a6;
$grey-600: #80868b;
$grey-700: #5f6368;
$grey-800: #3c4043;
$grey-900: #202124;
$black: #000000;
$blue-50: #e8f0fe;
$blue-100: #d2e3fc;
$blue-200: #aecbfa;
$blue-300: #8ab4f8;
$blue-400: #669df6;
$blue-500: #4285f4;
$blue-600: #1a73e8;
$blue-700: #1967d2;
$blue-800: #185abc;
$blue-900: #174ea6;
$green-50: #e6f4ea;
$green-100: #ceead6;
$green-200: #a8dab5;
$green-300: #81c995;
$green-400: #5bb974;
$green-500: #34a853;
$green-600: #1e8e3e;
$green-700: #188038;
$green-800: #137333;
$green-900: #0d652d;
$red-50: #fce8e6;
$red-100: #fad2cf;
$red-200: #f6aea9;
$red-300: #f28b82;
$red-400: #ee675c;
$red-500: #ea4335;
$red-600: #d93025;
$red-700: #c5221f;
$red-800: #b31412;
$red-900: #a50e0e;
$yellow-50: #fef7e0;
$yellow-100: #feefc3;
$yellow-200: #fde293;
$yellow-300: #fdd663;
$yellow-400: #fcc934;
$yellow-500: #fbbc04;
$yellow-600: #f9ab00;
$yellow-700: #f29900;
$yellow-800: #ea8600;
$yellow-900: #e37400;
$purple-100: #ea80fc;
$purple-900: #681da8;
$primary-color: #1c4da1;

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-0 {
    margin-top: 0;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-5 {
    margin-top: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-15 {
    margin-top: 15px;
}

.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pt-0 {
    padding-top: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pt-15 {
    padding-top: 15px;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
    font-family: "Noto Sans", sans-serif;
}

button {
    padding: 8px 15px;
    background-color: $red-700;
    border-radius: 20px;
    color: $grey-0;
    outline: none;
    border: none;

    &.active,
    &:hover {
        background-color: $red-900;
    }

    &.rewardsbtn {
        padding: 8px 15px;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        color: #252525;
        outline: none;
        border: none;

        &.instrbtn {
            width: 200px;
            margin-left: 20px;
        }

        &.ml-10 {
            @media (max-width: 767px) {
                padding-left: 10px;
                padding-right: 10px;
                margin-left: 5px;
            }
        }

        &.active {
            font-weight: 600;
            background: #0094a5;
            color: #fff;
        }
    }
}

.customer_portal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid $grey-300;
    margin-bottom: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: $white;

    a {
        figure {
            margin-bottom: 0;

            img {
                width: auto;
                max-height: 50px;

                @media only screen and (max-width: 500px) {
                    max-height: 40px;
                }

                @media only screen and (max-width: 375px) {
                    max-height: 30px;
                }
            }
        }
    }

    .dashboard__heading {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a.logoutBtn {
            background: #be1e15;
            color: $white;
            padding: 8px 15px;
            border-radius: 40px;
            font-size: 16px;
            font-weight: 600;

            @media only screen and (max-width: 500px) {
                i {
                    font-size: 14px;
                }
                font-size: 0;
            }
        }

        h6 {
            margin-bottom: 0;
            color: $primary-color;

            &:first-child {
                margin-right: 10px;
            }

            a {
                font-weight: 300;
                display: flex;
                align-items: center;
                color: $red-700;

                i {
                    margin-right: 2px;
                }
            }
        }
    }
}

.customer_portal {
    $customer_portal: ".customer_portal";

    #{$customer_portal}__mainPage {
        display: flex;
        flex-direction: column;

        #{$customer_portal}__content {
            padding: 0 15px;

            #{$customer_portal}__headerbox {
                padding: 15px;
                // border: 1px solid $grey-200;
                border-radius: 10px;
                margin-bottom: 15px;
                background: $white;

                box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);

                .page_heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    @media (max-width: 480px) {
                        flex-direction: column;
                    }

                    &__left {
                        h4 {
                            color: #252525;
                            font-size: 20px;
                            margin-bottom: 0;
                            font-weight: 700;
                        }
                    }

                    &__right {
                        .share_box {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 15px 10px;
                            border-radius: 10px;
                            // background-color: $blue-100;
                            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            // margin-bottom: 20px;

                            @media only screen and (max-width: 767px) {
                                padding-top: 5px;
                                justify-content: space-between;
                                padding-bottom: 0;
                            }

                            @media (max-width: 480px) {
                                flex-direction: column;
                            }

                            h6 {
                                margin-bottom: 0;

                                @media only screen and (max-width: 767px) {
                                    line-height: 1.4;
                                    margin-bottom: 10px;
                                }

                                span {
                                    font-weight: 700;
                                }
                            }

                            .sharing_box {
                                display: flex;
                                align-items: center;
                                margin-left: 10px;

                                .copy_btn {
                                    width: 32px;
                                    height: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: rgba(0, 0, 0, 0.4);
                                    border-radius: 20px;
                                    margin-right: 8px;

                                    i {
                                        color: $white;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        width: 25px;
                                        height: 25px;
                                        margin-right: 5px;
                                    }
                                }

                                .sharing_icon {
                                    margin-right: 8px;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        margin-right: 5px;

                                        svg {
                                            width: 25px;
                                            height: 25px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .loyalty_points_box {
                    background: #f1f1f1;
                    border-radius: 80px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    margin-bottom: 15px;

                    @media (max-width: 500px) {
                        flex-direction: column;
                        border-radius: 10px;
                    }

                    &__right {
                        display: flex;
                        align-items: center;

                        @media (max-width: 500px) {
                            margin-bottom: 5px;
                        }

                        button {
                            &:first-child {
                                margin-right: 8px;
                            }
                        }

                        &__icon {
                            $size: 40px;
                            width: $size;
                            height: $size;
                            background: $white;
                            border-radius: 50%;
                            margin-bottom: 0;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                max-height: 30px;
                            }
                        }

                        &__info {
                            margin-left: 10px;

                            h6 {
                                font-size: 16px;
                                margin-bottom: 0;
                                color: #252525;
                                font-weight: 700;
                                display: flex;
                                align-items: center;

                                span {
                                    &.divider {
                                        margin-left: 4px;
                                        margin-right: 4px;
                                        color: #999;
                                    }

                                    &.counter {
                                        margin-bottom: 0;
                                        color: #0094a5;
                                        font-size: 20px;
                                        font-weight: 900;
                                    }
                                }
                            }
                        }
                    }

                    &__left {
                        @media (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                    }

                    h2 {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        img {
                            width: 40px;
                            margin-right: 5px;
                        }
                    }
                }

                .points_expiring_box {
                    background-color: #f1f1f1;
                    padding: 10px;
                    border-radius: 10px;
                    border-radius: 80px;
                    display: flex;
                    align-items: center;

                    &__info {
                        margin-left: 10px;

                        p {
                            margin-bottom: 0;
                            color: #252525;
                            font-weight: 600;

                            &:first-child {
                                margin-bottom: 5px;
                            }

                            span {
                                font-weight: 900;
                                font-size: 16px;
                            }

                            &:last-child {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .banner_box {
                img {
                    width: 100%;
                }
            }

            .tabs_box {
                min-height: 227px;
                margin-bottom: 20px;
                margin-top: 30px;

                ul {
                    margin-bottom: 15px;
                    border: none;

                    li {
                        button {
                            border: 1px solid $grey-200;
                            border-radius: 20px;
                            margin-right: 10px;
                            color: $grey-700;

                            &.active {
                                background-color: $red-700;
                                color: $grey-0;
                                border-color: $red-700;

                                &:hover {
                                    background-color: $red-700;
                                    border-color: $red-700;
                                }
                            }

                            &:hover {
                                background-color: $red-300;
                                color: $grey-0;
                                border-color: $red-300;
                            }

                            @media only screen and (max-width: 600px) {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .tab-content {
                    padding: 15px;
                    background-color: $grey-50;
                    border: 1px solid $grey-300;
                    border-radius: 10px;

                    .tabs_row {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        &.cardSaprate {
                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }
                        }

                        .overview_box {
                            width: calc(100% / 3 - 15px);
                            margin-right: 15px;
                            padding: 20px;
                            border: 1px solid $grey-50;
                            background-color: $grey-0;
                            border-radius: 5px;
                            box-shadow: rgba(149, 157, 165, 0.5) 0px 5px 8px;
                            margin-bottom: 15px;
                            align-self: stretch;
                            text-align: center;
                            position: relative;

                            @media (max-width: 768px) {
                                width: calc(100% / 2 - 15px);
                            }

                            h6 {
                                color: $black;
                            }

                            figure {
                                img {
                                    max-height: 50px;
                                }
                            }

                            &.drk {
                                background-color: $grey-700;
                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                // width: calc(25% - 15px);
                                border-radius: 15px;

                                h6 {
                                    font-weight: 700;
                                }

                                h6,
                                p {
                                    color: $white;
                                    margin-bottom: 0;
                                }

                                figure {
                                    img {
                                        filter: invert(1);
                                        max-height: 50px;
                                    }
                                }
                            }

                            &.light {
                                background-color: $white;
                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                // width: calc(25% - 15px);
                                border-radius: 15px;
                                pointer-events: none;

                                h6 {
                                    font-weight: 700;
                                }

                                h6,
                                p {
                                    color: #4a4a4a;
                                    margin-bottom: 0;
                                }

                                figure {
                                    img {
                                        // filter: invert(1);
                                        max-height: 50px;
                                    }
                                }
                            }

                            &.blur {
                                background-color: $grey-700;
                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                // width: calc(25% - 15px);
                                border-radius: 15px;
                                pointer-events: none;
                                opacity: 0.4;

                                h6 {
                                    font-weight: 700;
                                }

                                h6,
                                p {
                                    color: $white;
                                    margin-bottom: 0;
                                }

                                figure {
                                    img {
                                        filter: invert(1);
                                        max-height: 50px;
                                    }
                                }
                            }

                            &.review {
                                background-color: $white;
                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                // width: calc(25% - 15px);
                                border-radius: 15px;

                                h6 {
                                    font-weight: 700;
                                }

                                h6,
                                p {
                                    color: $black;
                                    margin-bottom: 0;
                                }

                                p {
                                    &.errorMsg {
                                        margin-top: 10px;
                                        color: red;
                                    }
                                }

                                figure {
                                    img {
                                        // filter: invert(1);
                                        max-height: 50px;
                                    }
                                }
                            }
                        }
                    }

                    .history_row {
                        display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;

                        .history_box {
                            width: calc(100% / 3 - 15px);
                            margin-right: 15px;
                            padding: 15px;
                            border: 1px solid $grey-50;
                            background-color: $grey-0;
                            border-radius: 5px;
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            margin-bottom: 15px;
                            align-self: stretch;
                            display: flex;
                            align-items: center;

                            .history_details {
                                // width: 60%;
                                width: calc(100% - 130px);

                                h5 {
                                    font-size: 14px;
                                    color: $black;
                                    margin-bottom: 5px;
                                    word-wrap: break-word;
                                }

                                p {
                                    margin-bottom: 0;
                                    font-size: 10px;
                                    color: $black;
                                }
                            }

                            .history_points {
                                // width: 40%;
                                min-width: 130px;
                                justify-content: flex-end;
                                display: flex;

                                p {
                                    display: inline-flex;
                                    padding: 8px 10px;
                                    font-size: 10px;
                                    background-color: $black;
                                    color: $white;
                                    border-radius: 20px;
                                    line-height: 1;
                                    margin-bottom: 0;

                                    &.refunded {
                                        background-color: $blue-500;
                                        color: $white;
                                    }

                                    &.success {
                                        background-color: $green-500;
                                        color: $white;
                                    }

                                    &.redeemed {
                                        background-color: $red-500;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }

                    .rewards_row {
                        display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .rewards_box {
                            width: calc(100% / 2 - 10px);
                            margin-right: 10px;
                            padding: 30px;
                            border: 1px solid $grey-50;
                            background-color: $grey-0;
                            border-radius: 5px;
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            margin-bottom: 20px;
                            align-self: stretch;
                            display: flex;
                            align-items: center;

                            &:nth-child(2n) {
                                margin-right: 0;
                            }

                            .rewards_ibox {
                                width: 30%;

                                img {
                                    border-radius: 5px;
                                }
                            }

                            .rewards_cbox {
                                width: 70%;
                                padding-left: 15px;

                                h4 {
                                    color: $black;
                                    font-size: 20px;
                                    font-weight: 700;
                                }

                                p {
                                    color: $black;
                                    margin-bottom: 4px;
                                    font-size: 14px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    span {
                                        font-weight: 600;
                                    }
                                }

                                small {
                                    color: $black;
                                    font-size: 10px;
                                }
                            }

                            .giftStar {
                                color: #f00;
                            }
                        }
                    }

                    .giftcards_row {
                        display: flex;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .giftcards_box {
                            width: calc(100% / 2 - 10px);
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            border-radius: 8px;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            margin-bottom: 20px;
                            cursor: pointer;

                            .giftcards_box_inner {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                position: relative;

                                .giftcards_ibox {
                                    width: 40%;

                                    img {
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                    }
                                }

                                .giftcards_cbox {
                                    width: 60%;
                                    padding-left: 10px;

                                    h4 {
                                        color: $black;
                                        font-size: 20px;
                                        font-weight: 700;

                                        @media only screen and (max-width: 600px) {
                                            font-size: 18px;
                                        }
                                    }

                                    p {
                                        font-size: 16px;
                                        margin-bottom: 8px;
                                        color: $black;
                                        font-weight: 500;
                                        color: $black;

                                        span {
                                            color: $blue-900;
                                        }

                                        @media only screen and (max-width: 600px) {
                                            font-size: 12px;
                                            margin-bottom: 2px;

                                            &:last-child {
                                                margin-bottom: 5px;
                                            }
                                        }
                                    }

                                    ul {
                                        padding-left: 0;
                                        display: flex;

                                        li {
                                            list-style: none;
                                            padding: 5px;
                                            background-color: $blue-600;

                                            &:first-child {
                                                margin-right: 10px;
                                                color: $white;
                                            }

                                            &.success {
                                                background-color: $green-700;
                                                color: $white;
                                            }

                                            &.refunded {
                                                background-color: $blue-700;
                                                color: $white;
                                            }

                                            &.failed {
                                                background-color: $red-700;
                                                color: $white;
                                            }
                                        }

                                        @media only screen and (max-width: 768px) {
                                            .giftcards_cbox {
                                                padding: 5px 0 5px 15px;
                                            }
                                        }

                                        @media only screen and (max-width: 600px) {
                                            margin-top: 8px;
                                        }
                                    }

                                    small {
                                        color: $black;
                                    }
                                }

                                &:after {
                                    content: "";
                                    width: 15px;
                                    height: 15px;
                                    border-top: 1px solid $black;
                                    border-right: 1px solid $black;
                                    transform: rotate(135deg);
                                    display: inline-flex;
                                    position: absolute;
                                    right: 15px;

                                    @media only screen and (max-width: 600px) {
                                        width: 10px;
                                        height: 10px;
                                        right: 10px;
                                    }
                                }
                            }

                            .giftcards_obox {
                                display: none;
                                padding: 10px;

                                p {
                                    color: $black;
                                    margin-bottom: 0;
                                }
                            }

                            &.active {
                                .giftcards_box_inner {
                                    &:after {
                                        transform: rotate(315deg);
                                    }
                                }

                                .giftcards_obox {
                                    display: flex;
                                }
                            }

                            @media only screen and (max-width: 600px) {
                                width: 100%;
                                padding: 0;
                            }
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        .history_row {
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .history_box {
                                width: calc(100% / 2 - 7.5px);
                                margin-right: 0;
                                padding: 10px;

                                .history_points {
                                    p {
                                        padding: 8px;
                                        font-size: 9px;
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 600px) {
                        padding: 10px;

                        .tabs_row,
                        .history_row,
                        .rewards_row,
                        .giftcards_row {
                            flex-wrap: wrap;

                            .overview_box,
                            .history_box,
                            .rewards_box {
                                width: 100%;
                                margin: 0 0 10px;
                                padding: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            .rewards_box {
                                .rewards_cbox {
                                    h4 {
                                        font-size: 18px;
                                    }

                                    small {
                                        margin-top: 10px;
                                        font-size: 8px;
                                        line-height: 1.32;
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #{$customer_portal}__footer {
        display: flex;
        flex-direction: column;

        .footer__box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $black;
            padding: 15px;

            .footer_links {
                width: 35%;

                ul {
                    margin-bottom: 0;
                    padding: 0;

                    li {
                        display: inline-flex;
                        margin-right: 15px;

                        a {
                            color: $white;

                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                    }
                }
            }

            .social_links {
                @media (max-width: 767px) {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 6px;
                }

                &__icon {
                    $size: 36px;
                    width: $size;
                    height: $size;
                    border-radius: 50%;
                    background: $white;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 3px;
                    color: #252525;
                    transition: 0.3s;

                    i {
                        font-size: 16px;
                    }

                    &:hover {
                        color: white;

                        &.facebook_ic {
                            background-color: #4b69b1;
                        }

                        &.twitter_ic {
                            background-color: #37b1e2;
                        }

                        &.linkedin_ic {
                            background-color: #0678b6;
                        }

                        &.instagram_ic {
                            background-image: linear-gradient(#9d279f, #f6b50c);
                        }
                    }
                }
            }

            .footer_copyright {
                width: 35%;

                p {
                    color: $white;
                    margin-bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            @media screen and (max-width: 768px) {
                flex-wrap: wrap;

                .footer_links,
                .footer_copyright {
                    width: 100%;
                    text-align: center;
                }

                .footer_copyright {
                    margin-top: 10px;

                    p {
                        justify-content: center;
                    }
                }
            }
        }
    }
}

.internal_voucher_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .internal_voucher_box {
        width: calc(100% / 5 - 15px);
        margin-right: 15px;
        border: 1px solid $grey-100;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 15px;
        margin-bottom: 30px;
        transition: all 0.25s ease-in-out;
        align-self: stretch;

        &:hover {
            transform: translateY(-8px);
        }

        &:nth-child(5n) {
            margin-right: 0;

            @media screen and (max-width: 992px) {
                margin-right: 15px;
            }
        }

        @media screen and (max-width: 992px) {
            width: calc(100% / 2 - 15px);
        }

        // @media screen and (max-width: 600px) {
        //     width: calc(100%);
        //     margin-right: 0;
        //     // justify-content: space-between;
        // }
        @media screen and (max-width: 600px) {
            width: calc(100% / 2 - 7.5px);

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        a {
            display: flex;
            flex-direction: column;

            .internal_voucher_ibox {
                img {
                    overflow: hidden;
                    border-radius: 15px;
                }
            }

            .internal_voucher_cbox {
                padding: 10px;

                h5 {
                    color: $black;
                    margin-bottom: 10px;
                    font-size: 18px;
                }

                p {
                    color: $black;
                    margin: 0;
                }
            }
        }
    }
}

.internal_giftcard_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media screen and (max-width: 600px) {
        justify-content: space-between;
    }

    .internal_giftcard_box {
        width: calc(100% / 5 - 15px);
        margin-right: 15px;
        border: 1px solid $grey-100;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 10px;
        margin-bottom: 20px;
        transition: all 0.25s ease-in-out;
        align-self: stretch;

        @media screen and (max-width: 992px) {
            width: calc(100% / 2 - 15px);
        }

        @media screen and (max-width: 600px) {
            width: calc(100% / 2 - 7.5px);

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        &:hover {
            transform: translateY(-8px);
        }

        &:nth-child(5n) {
            margin-right: 0;
        }

        a {
            display: flex;
            flex-direction: column;

            .internal_giftcard_ibox {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100px;
                img {
                    overflow: hidden;
                    border-radius: 15px;
                    max-height: 80%;
                }
            }

            .internal_giftcard_cbox {
                padding: 10px;

                h5 {
                    color: $black;
                    text-align: center;
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }
}

.allCardContainer {
    background: $white;
    border: #ddd solid 1px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    margin-top: 15px;

    &__top {
        .chooseVc {
            font-weight: 700;
            color: #252525;
            margin-right: 10px;
        }

        .nav {
            &.nav-tabs {
            }
        }

        .internal_voucher_box {
            a {
                .internal_voucher_ibox {
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // height: 100px;
                    img {
                        max-width: 80%;
                    }
                }

                .internal_voucher_cbox {
                    text-align: center;

                    h5 {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 3px;
                        word-wrap: break-word;
                    }

                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.banner_box {
    .slick-slider {
        .slick-list {
            .slick-track {
                .slick-slide {
                    figure {
                        img {
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}

.giftCardDetail {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background: $white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    min-height: 89px;

    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    &__left {
        width: calc(50% - 15px);
        padding: 5rem;

        @media (max-width: 767px) {
            width: 100%;
            padding: 1rem;
        }

        img {
            max-width: 600px;
            width: 100%;
        }
    }

    &__right {
        width: calc(50% - 15px);
        padding: 1rem;

        .card_denominations {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            &__btn {
                margin: 2px 10px 2px 0;
                margin-bottom: 8px;
                button {
                    background-color: blue;
                    color: #ffffff;
                    border-radius: 5px;
                    font-weight: 700;
                }

                :disabled {
                    background-color: #b4b4b4;
                    cursor: no-drop;
                }
            }
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.report_item_container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.report_item {
    width: calc(100% / 3 - 15px);
    margin-right: 15px;
    padding: 15px;
    border: 1px solid #cfcfcf;
    background-color: $white;
    border-radius: 5px;
    margin-bottom: 15px;
    align-self: stretch;
    border-radius: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        width: calc(100% / 2 - 15px);
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-right: 0;
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
        color: #252525;
        margin-bottom: 0;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    h5 {
        font-size: 25px;
        color: #181c1c;
        font-weight: 800;
        margin-bottom: 0;

        @media (max-width: 480px) {
            font-size: 20px;
        }
    }

    &__icon {
        $size: 40px;
        width: $size;
        height: $size;
        border-radius: 50%;
        background: url("../../public/Images/open-document.svg") center center no-repeat #0a3e42;
        background-size: 16px auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }

    &__info {
        width: calc(100% - 40px);
        padding-left: 10px;

        &__clickable {
            span {
                padding: 0;

                &.divider {
                    padding-left: 5px;
                    padding-right: 5px;
                    height: 23px;
                    line-height: 20px;
                }
            }

            .earnedValueBtn {
                display: flex;
                align-items: center;
                padding: 3px 12px;
            }
        }
    }
}

.backAro {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: #f1f1f1;
    width: 50px;
    height: 30px;
    border-radius: 30px;

    &:hover {
        background: #ddd;
    }

    img {
        margin-right: 5px;
    }
}

.redemption-points {
    display: flex;
    background: #0094a5;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;

    &__left {
        width: 50%;
        height: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        color: $white;
        font-weight: 500;
    }

    &__right {
        width: 50%;
        height: 44px;
        position: relative;
        background: #65a608;
        display: flex;
        align-items: center;
        padding-left: 20px;
        color: $white;
        font-weight: 700;
        border-radius: 0 6px 6px 0;

        &__equal {
            $size: 24px;
            width: $size;
            height: $size;
            background: #65a608;
            color: $white;
            position: absolute;
            left: -12px;
            top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
}

.btn-close {
    &.newStyle {
        &:hover {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
}

.banner_box {
    .slick-dots {
        bottom: 0;
    }
}

.copy_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}

.copy_msg {
    margin-left: 8px;
    color: white;
    font-size: 0.9em;
    background-color: #000000;
    position: absolute;
    top: -50%;
    left: 50%;
    white-space: pre;
    transform: translate(-50%, -50%);
    padding: 5px;
    border-radius: 4px;
}

.voucher-heading {
    background-color: #f1f1f1;
    border: #ddd solid 1px;
    // border-bottom: #ddd solid 1px;
    padding: 10px;
    font-weight: 700;
    margin-bottom: 20px;

    border-radius: 6px;
    position: relative;

    span {
        // background: white;
        // padding: 0 10px;
        // position: relative;
        font-size: 18px;
        color: #252525;
    }
}

.hero-banner {
    margin-bottom: 20px;
    padding: 15px;
    img {
        width: 100%;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
    img {
        border-radius: 10px;
    }
}

// .point-redeem-section {
//     display: flex;
//     justify-content: space-between;
//     border-bottom: $primary-color solid 2px;

//     &__left {
//         display: flex;
//         span {

//         }
//         img {

//         }
//     }
// }

.new_header_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #1c4da1;
    padding: 15px;
    margin-top: 20px;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .header_menu_list {
        width: 60%;
        display: flex;
        justify-content: end;
        align-items: center;
        position: relative;

        @media (max-width: 1024px) {
            width: 100%;
            justify-content: center;
        }

        @media (max-width: 991px) {
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        @media only screen and (min-width: 1080px) and (max-width: 1300px) {
            width: 60%;
        }

        ul {
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            li {
                display: inline-flex;
                padding: 0 15px;

                @media (max-width: 500px) {
                    padding: 0 10px;
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    color: #1c4da1;
                    padding: 0;
                    border: none;
                    font-size: 15px;

                    &.active {
                        font-weight: bold;
                        border: none;
                        color: #1c4da1;
                    }
                }

                &.redeemLinkBtn {
                    a {
                        padding: 8px 15px;
                        background-color: #f29900;
                        border-radius: 20px;
                        font-weight: 600;
                        color: #ffffff;
                    }
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .mobile_box {
                display: none;
                position: absolute;
                width: 100%;
                background-color: #fff;
                top: 60px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                border-radius: 5px;
                z-index: 1;

                &.active {
                    display: flex;
                }

                ul {
                    display: flex !important;
                    width: 100%;

                    li {
                        display: flex;
                        width: 100%;
                        padding: 5px;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .header_menu_box {
        width: 40%;
        display: flex;
        align-items: center;

        @media (max-width: 500px) {
            flex-direction: column;
        }
        @media (max-width: 820px) {
            margin-bottom: 10px;
            width: 100%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
            justify-content: space-between;
        }

        @media only screen and (min-width: 1080px) and (max-width: 1300px) {
            width: 40%;
            justify-content: space-between;
            flex-direction: column;
            align-items: start;
        }

        .choose_rewards_box {
            width: 50%;
            display: flex;
            justify-content: center;

            @media (max-width: 820px) {
                justify-content: flex-end;
            }

            @media (max-width: 500px) {
                width: 100%;
                justify-content: center;
            }

            a {
                text-decoration: none;
                padding: 5px 15px;
                border-radius: 25px;
                color: #fff;
                background-color: #ec9c34;
                display: inline-block;
                // font-family: 'manrope_bold';
                font-size: 24px;
                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }

        .header_points_box {
            // width: 35%;

            @media (max-width: 500px) {
                margin-bottom: 10px;
                width: 100%;
            }

            h4 {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 0;
                color: #1c4da1;
                // font-family: 'manrope_regular';
                display: flex;
                align-items: center;

                @media (max-width: 820px) {
                    justify-content: flex-start;
                }

                @media (max-width: 500px) {
                    justify-content: center;
                }

                img {
                    width: 30px;
                    margin: 0 8px;
                }

                span {
                    font-size: 24px;
                }
            }
        }
    }
}

.section_1_box,
.section_2_box {
    padding: 60px 15px;
}

.inner_section_1 h3,
.inner_section_2 h3 {
    text-align: center;
    color: #1c4da1;
    margin-bottom: 20px;
    font-size: 36px;

    @media (max-width: 820px) {
        font-size: 28px;
        margin-bottom: 15px;
    }

    @media (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}

.inner_section_1 p,
.inner_section_2 p {
    text-align: center;
}

.rewards_main_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    margin: 0 auto;
    flex-wrap: wrap;

    @media (max-width: 820px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
}

.rewards_desbox {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
    padding: 30px;
    background: hsla(218, 70%, 37%, 1);
    background: linear-gradient(90deg, hsla(218, 70%, 37%, 1) 0%, hsla(180, 89%, 30%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(218, 70%, 37%, 1) 0%, hsla(180, 89%, 30%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(218, 70%, 37%, 1) 0%, hsla(180, 89%, 30%, 1) 100%);
    align-self: stretch;
    margin-bottom: 20px;
    border-radius: 20px;

    @media (max-width: 768px) {
        width: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 10px;
        padding: 15px;
        border-radius: 10px;
    }

    &:last-child {
        @media (max-width: 820px) {
            width: 100%;
        }
    }

    img {
        width: 60px;
        margin-bottom: 15px;
        filter: invert(1);
        @media (max-width: 820px) {
            width: 40px;
        }
    }

    h5 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #fff;
        text-align: left;
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
        color: #fff;
        text-align: left;
    }
}

.section_strip {
    padding: 10px;
    background-color: #1c4da1;

    h2 {
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: center;
        font-size: 24px;

        @media screen and (max-width: 480px) {
            font-size: 20px;
        }
    }
}

.rewards_main_box_2 {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    width: 800px;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .rewards_desbox_2 {
        width: calc(100% / 2 - 15px);
        margin-right: 20px;
        padding: 30px;
        border-right: 1px solid #000;

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            border-right: none;
            padding: 15px;
            margin-bottom: 15px;
            border: 1px solid #dadada;
        }

        img {
            margin-bottom: 15px;
        }

        h5 {
            font-size: 18px;
            color: #1c4da1;
        }

        ul {
            padding-left: 20px;

            li {
                font-size: 14px;
                margin-bottom: 15px;
            }
        }

        &:last-child {
            margin-right: 0;
            border-right: 0;
        }
    }
}

.tab_boxx {
    padding: 20px 15px;

    @media (max-width: 767px) {
        padding: 10px 0px;
    }

    .no-data-record {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
        color: #252525;
    }

    .history__completed {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        margin-bottom: 20px !important;

        &:last-child {
            margin-bottom: 0;
        }

        .history__completedList {
            display: flex;
            flex-direction: row;
            ul {
                &.list_all {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    // -moz-column-count: 3;
                    // -moz-column-gap: 20px;
                    // -webkit-column-count: 3;
                    // -webkit-column-gap: 20px;
                    // column-count: 3;
                    // column-gap: 20px;
                    // padding: 0;
                    // width: 100%;
                    @media (max-width: 1024px) {
                        -moz-column-count: 2;
                        -webkit-column-count: 2;
                        column-count: 2;
                    }
                    @media (max-width: 768px) {
                        -moz-column-count: 1;
                        -moz-column-gap: 0;
                        -webkit-column-count: 1;
                        -webkit-column-gap: 0;
                        column-count: 1;
                        column-gap: 0;
                        padding: 0;
                    }
                    li {
                        list-style: none;
                        // display: inline-block;
                        // width: 100%;
                        width: calc(100% / 3 - 15px);
                        margin-right: 15px;
                        margin-bottom: 20px;

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                        @media (max-width: 600px) {
                            width: 100%;
                            margin-right: 0;
                        }

                        .transaction_box {
                            background-color: #fff;
                            padding: 20px 15px;
                            //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #efefef;
                            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 3px;

                            // @media (max-width: 767px) {
                            //     box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 3px;
                            // }

                            @media (min-width: 768px) {
                                width: 100%;
                                margin-right: 0;
                            }

                            @media (max-width: 600px) {
                                align-items: self-start;
                            }

                            .transaction_details {
                                width: calc(60% - 5px);

                                .history_details {
                                    h5 {
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: pre;

                                        &:hover {
                                            white-space: wrap;
                                            word-break: break-word;
                                        }
                                    }
                                }
                            }

                            .history__points {
                                width: calc(40% - 5px);
                            }

                            .restrict_id {
                                max-width: 15ch;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                span {
                                    font-weight: 600;
                                }
                                &:hover {
                                    max-width: 40ch;
                                }
                            }
                            .transaction_id {
                                margin-bottom: 8px;
                                font-size: 12px;
                            }
                            .transaction_status {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                @media (max-width: 600px) {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }
                                span {
                                    &.transaction_time {
                                        font-size: 10px;
                                        margin-right: 15px;
                                        @media (max-width: 600px) {
                                            margin-bottom: 5px;
                                        }
                                    }
                                    &.method_key {
                                        padding: 4px 8px;
                                        color: #fff;
                                        border-radius: 4px;
                                        font-size: 10px;
                                        background-color: #1c4da1;
                                        max-width: 180px;
                                        white-space: pre;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        &.no_method {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .transaction_points {
                                font-size: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: end;
                                span {
                                    &.tp_value {
                                        padding: 4px 8px;
                                        color: #fff;
                                        border-radius: 4px;
                                        font-size: 10px;
                                        margin-bottom: 8px;
                                        display: inline-block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: pre;
                                        width: 100%;
                                        text-align: center;
                                        @media (max-width: 767px) {
                                            width: auto;
                                        }
                                        &.refunded {
                                            background-color: #00b9eb;
                                            color: #fff;
                                        }
                                        &.redeemed {
                                            background-color: red;
                                            color: #fff;
                                        }
                                    }
                                    &.redeemed {
                                        color: red;
                                    }
                                    &.refunded {
                                        color: #00b9eb;
                                    }

                                    &:hover {
                                        word-break: break-all;
                                        overflow: visible;
                                        white-space: break-spaces;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .giftcard__mainnbox {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
            width: 100%;
        }
    }

    .giftcard_order_history {
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;

        div {
            width: 100%;
        }
    }
}

// footer css
.footer__Box {
    padding: 0 15px;
    background-color: #1c4da1;

    .inner_footer {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: 40px 0 20px;
        border-bottom: 1px solid #fff;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 20px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .inner_footerbox {
            width: calc(100% / 3);

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 15px;
            }

            h4 {
                color: #fff;
                font-size: 20px;
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }

            p {
                color: #fff;
                padding-left: 30px;
                position: relative;

                &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background: url("../../public/Images/location.png") no-repeat;
                    background-size: contain;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
            }

            ul {
                padding: 0;

                @media (max-width: 768px) {
                    -moz-column-count: 2;
                    -moz-column-gap: 20px;
                    -webkit-column-count: 2;
                    -webkit-column-gap: 20px;
                    column-count: 2;
                    column-gap: 20px;
                    padding: 0;
                }

                li {
                    list-style: none;
                    margin-bottom: 10px;
                    position: relative;

                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            &.inner_footerbox_3 {
                ul {
                    li {
                        padding-left: 30px;

                        @media (max-width: 768px) {
                            padding-bottom: 10px;
                        }

                        &::before {
                            content: "";
                            background: url("../../public/Images/call.png") no-repeat;
                            background-size: contain;
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                        }

                        &:nth-child(2) {
                            &::before {
                                background: url("../../public/Images/mail.png") no-repeat;
                                background-size: contain;
                            }
                        }

                        &:last-child {
                            &::before {
                                background: url("../../public/Images/location.png") no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright_box {
        padding-bottom: 20px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        .copyright_innerbox {
            display: flex;
            justify-content: space-between;
            align-items: center;

            ul {
                padding: 0;

                li {
                    list-style: none;
                    display: inline-block;
                    color: #fff;

                    a {
                        color: #fff;
                        text-decoration: underline;
                    }

                    &:last-child {
                        content: "|";
                        color: #fff;
                        margin: 0 5px;
                    }
                }
            }
        }

        .copyright_arrowbox a {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;

            span {
                width: 10px;
                height: 10px;
                display: inline-block;
                border: none;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(225deg);
                margin-top: 5px;
            }
        }
    }
}

// .header {
//     &__left {
//         width: 30%;
//     }
//     &__right {
//         width: 70%;
//     }
// }

.fillter-keys {
    margin-top: 15px;
    margin-bottom: 20px;
    a {
        text-decoration: underline !important;
        margin: 0 3px;

        &.active {
            font-size: bold;
            background-color: #1c4da1;
            padding: 2px 4px;
            color: #fff;
            border-radius: 2px;
            text-decoration: none;
        }
    }
}

.inp-giftcard {
    border: #ddd solid 1px;
    padding: 6px 15px;
    border-radius: 4px;
    margin-left: 10px;
}

// .method_key{
//     padding: 4px 8px;
//     color: #FFF;
//     border-radius: 4px;
//     font-size: 10px;
//     background-color: #1C4DA1;
//     max-width: 180px;
//     white-space: pre;
//     text-overflow: ellipsis;
//     overflow: hidden;
// }

.filter-key-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.dashboard__paginationBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            flex-direction: row;
            margin: 0;
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: inline-block;
            }
        }
    }
}

.function_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .chooseVc {
        margin-right: 10px;
        font-size: 16px;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    a {
        &.backAro {
            margin-bottom: 0;
        }
    }

    .points_box {
        img {
            width: 30px;
            margin: 0 8px;
        }

        h4 {
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }
    }
}

.tab-content {
    padding: 15px;
    background-color: $grey-50;
    border: 1px solid $grey-300;
}

.giftcards_row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    .giftcards_box {
        width: calc(100% / 2 - 10px);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        cursor: pointer;

        .giftcards_box_inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .giftcards_ibox {
                width: 40%;

                img {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
            }

            .giftcards_cbox {
                width: 60%;
                padding-left: 10px;

                h4 {
                    color: $black;
                    font-size: 20px;
                    font-weight: 700;

                    @media only screen and (max-width: 600px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 8px;
                    color: $black;
                    font-weight: 500;
                    color: $black;

                    span {
                        color: $blue-900;
                    }

                    @media only screen and (max-width: 600px) {
                        font-size: 12px;
                        margin-bottom: 2px;

                        &:last-child {
                            margin-bottom: 5px;
                        }
                    }
                }

                ul {
                    padding-left: 0;
                    display: flex;

                    li {
                        list-style: none;
                        padding: 5px;
                        background-color: $blue-600;

                        &:first-child {
                            margin-right: 10px;
                            color: $white;
                        }

                        &.success {
                            background-color: $green-700;
                            color: $white;
                        }

                        &.refunded {
                            background-color: $blue-700;
                            color: $white;
                        }

                        &.failed {
                            background-color: $red-700;
                            color: $white;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        .giftcards_cbox {
                            padding: 5px 0 5px 15px;
                        }
                    }

                    @media only screen and (max-width: 600px) {
                        margin-top: 8px;
                    }
                }

                small {
                    color: $black;
                }
            }

            &:after {
                content: "";
                width: 15px;
                height: 15px;
                border-top: 1px solid $black;
                border-right: 1px solid $black;
                transform: rotate(135deg);
                display: inline-flex;
                position: absolute;
                right: 15px;

                @media only screen and (max-width: 600px) {
                    width: 10px;
                    height: 10px;
                    right: 10px;
                }
            }
        }

        .giftcards_obox {
            display: none;
            padding: 10px;

            p {
                color: $black;
                margin-bottom: 0;
            }
        }

        &.active {
            .giftcards_box_inner {
                &:after {
                    transform: rotate(315deg);
                }
            }

            .giftcards_obox {
                display: flex;
            }
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            padding: 0;
        }
    }
}

.history__completedList {
    display: flex; /* Use flexbox for horizontal layout */
    flex-wrap: wrap; /* Allow wrapping if items exceed container width */
    gap: 1rem; /* Add space between items */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.history__completedList li {
}

.section {
    &.newStyle {
        .signUpPage__main {
            .signUpPage__left {
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: $primary-color;

                @media (max-width: 1024px) {
                    height: auto;
                    padding: 15px;
                    padding-bottom: 30px;
                }

                .sliderBox {
                    max-width: 400px;
                    @media (max-width: 1024px) {
                        width: 100%;
                    }
                }
                .offer-card-item {
                    display: flex !important;
                    justify-content: center;
                    margin: 0;
                }
            }
            .signUpPage__right {
                height: 100vh;
                border-radius: 0;
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                @media (max-width: 1024px) {
                    height: auto;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .signUpPage__right__inside {
                    max-width: 600px;
                }
            }
        }
    }
}

.offer-card-item {
    background: #fff;
    width: calc(100% / 4 - 24px);
    border-radius: 20px;
    margin: 0 12px;
    margin-bottom: 12px;
    div {
        div {
            margin: 0 !important;
        }
    }

    @media (max-width: 1080px) {
        width: calc(100% / 3 - 24px);
    }

    @media (max-width: 1023px) {
        width: calc(100% / 2 - 24px);
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    img {
        max-height: 200px;
        border-radius: 0;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #252525;
    }

    &__fig {
        // height: 240px;
        overflow: hidden;
        @media (max-width: 992px) {
            height: auto;
        }
        img {
            width: 100%;
            border-radius: 20px;
        }
    }

    &__content {
        padding: 25px;
        h4 {
            font-size: 22px;
            font-weight: 700;
            color: #252525;
        }
        h6 {
            font-weight: normal;
            font-size: 14px;
        }
        .btn {
            display: inline-block;
        }
    }
}

.offer-wapper {
    .offer-header {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        &__logo {
            img {
                max-height: 50px;
            }
        }
        .container {
            height: 90px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .offer-header-login-signup {
                ul {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        padding: 0 5px;
                        a {
                            background: $primary-color;
                            color: #fff;
                            padding: 7px 15px;
                            border-radius: 5px;
                            // &.signup {
                            //     background-color: #137333;
                            // }
                        }
                    }
                }
            }
        }
    }

    .offer-slider {
        &__item {
            img {
                width: 100%;
            }
        }
        .slick-dots {
            bottom: 15px;
            li {
                button {
                    &::before {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .offer-mid-container {
        padding: 30px 0 30px 0;
        background-color: #f9f9f9;
        .section-header {
            text-align: center;
            margin-bottom: 20px;
            h2 {
                // text-transform: uppercase;
                color: #181c1c;
            }
        }

        .offer-card-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px;
            .offer-card-item {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
                &.newOffers {
                    width: calc(100% / 3 - 24px);
                    
                    @media (max-width: 992px) {
                        width: calc(100% / 2 - 24px);
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                &__inside {
                    &__figure {
                        border-radius: 0;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        background-size: cover;
                        position: relative;
                        margin-bottom: 0;
                        img {
                            max-height: inherit;
                        }
                        .offerTitle {
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.3);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h4 {
                                font-size: 18px;
                                margin-bottom: 0;
                                padding: 15px 15px;
                                line-height: 1.3;
                            }
                        }
                    }
                    &__body {
                        padding: 20px 20px;
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
            .offer-crd {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
                &__inside {
                    &__header {
                        background: #0a3e42;
                    }
                    &__body {

                    }
                }
            }
        }
    }

    .offer-footer {
        background: #173c54;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            .copyr {
                color: rgba(255, 255, 255, 0.6);
                margin-bottom: 0;
            }
        }

        &__nav {
            ul {
                display: flex;
                list-style: none;
                margin: 0;
                li {
                    a {
                        padding: 0 10px;
                        color: $white;
                    }
                }
            }
        }
    }
}

.social_links {
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 6px;
    }

    &__icon {
        $size: 36px;
        width: $size;
        height: $size;
        border-radius: 50%;
        background: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px;
        color: #252525;
        transition: 0.3s;

        i {
            font-size: 16px;
        }

        &:hover {
            color: white;

            &.facebook_ic {
                background-color: #4b69b1;
            }

            &.twitter_ic {
                background-color: #37b1e2;
            }

            &.linkedin_ic {
                background-color: #0678b6;
            }

            &.instagram_ic {
                background-image: linear-gradient(#9d279f, #f6b50c);
            }
        }
    }
}

.share_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .sharing_text {
        font-size: 14px;
        margin-bottom: 0;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 12px;
        }
    }

    .sharing_box {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .copy_btn {
            margin-right: 4px;
            width: 32px;
            height: 32px;
            background-color: $black;
            border-radius: 32px;
            color: $white;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 480px) {
                width: 25px;
                height: 25px;
            }
        }

        button {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }

            @media only screen and (max-width: 480px) {
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

.urlBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;

    input {
        width: 100%;
        border-radius: 20px;
        outline: none;
        padding-right: 20%;

        &:hover {
            border-color: #ccc !important;
        }
    }

    button {
        position: absolute;
        right: 0;
    }
    .rewarded {
        margin: 0 auto;
        color: #137333 !important;
        font-weight: 500;
    }
}

p {
    &.campaignhome_box {
        display: flex;
        justify-content: center;
        width: 100%;
        font-family: "Noto Sans";

        div {
            div {
                h3 {
                    font-size: 22px;
                    line-height: 1.28;
                }

                p {
                    line-height: 1.54;
                }
            }
        }
    }
}
.highlighted_box {
    a {
        &.highlighted {
            padding: 8px 15px;
            background-color: $yellow-700;
            border-radius: 20px;
            font-weight: 600;
            color: $white;
        }
    }
}

#tabs-nav {
    @media only screen and (max-width: 991px) {
        display: none;
    }
}
.iconbox {
    display: none;

    @media only screen and (max-width: 991px) {
        display: flex;
    }

    img {
        max-height: 25px;
    }
}

#share_referral {
    margin-right: 5px;
}

.page_heading__right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.card-item-center-block {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $white;
    padding: 15px;
    border-radius: 10px;

    div {
        width: 100%;
        h4 {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            color: #252525;
            display: flex;
            justify-content: center;
            height: 50px;
            align-items: center;
        }
        img {
            width: inherit !important;
            height: inherit !important;
            max-height: inherit !important;
            border-radius: 0;
        }
        p {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    &.other {
        padding: 15px;
        position: relative;
        // position: absolute;
        // background: none;
        // padding: 30px 30px 15px 30px;
        // text-align: center;
        // left: 0;
        // top: 0;
        // height: 100%;
        // align-items: center;

        .internalText {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            padding: 0 50px;
            font-weight: 600;
            color: #252525;

            h5 {
                font-weight: 800;
                font-size: 20px;
            }

            p {
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
}

.campaignhome_box {
    img {
        max-height: 200px;
    }
}

.signUpPage {
    &__slider {
        .slick-slider {
            .offer-card-item {
                background: none;
                .card-item-center-block {
                    width: 400px;
                    p {
                        min-height: 50px;
                    }
                }
            }
        }
    }
}


.mll-5{
    margin-left: 5px;
}

button {
    &.rds {
        border-radius: 4px;
    }
}

.app-steps {
    text-align: center;
    position: relative;

    // @media (max-width: 768px) {
    //     margin-bottom: 50px;
    // }

    &:last-child {
        &::before {
            display: none;
        }
    }

    &::before {
        content: '';
        width: 25px;
        height: 41px;
        background: url('../../public/Images/step-next-arrow-1.svg') left top no-repeat;
        background-size: contain;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 767px) {
            width: 15px;
            height: 31px;
            right: -6px;
        }
    }
    &__number {
        $size: 70px;
        width: $size;
        height: $size;
        background: $primary-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;

        @media (max-width: 768px) {
            $size: 50px;
            width: $size;
            height: $size;
        }
    }
    figcaption {
        h4 {
            font-size: 18px;
            color: #252525;
            font-weight: 700;
            text-transform: uppercase;
            @media (max-width: 992px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        p {
            @media (max-width: 992px) {

            }
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}


.accordion-container {
    margin-top: 30px;
    .card {
        margin-bottom: 10px;
        .card-header {
            background: #fff;
            border-radius: 5px;
            border-bottom: none;
            .btn {
                &.btn-link {
                    color: #252525;
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 0;
                    padding-right: 35px;
                    font-size: 14px;
                    text-align: left;
 
                    @media (max-width: 767px){
                        font-size: 12px;
                    }
 
                    .iconx {
                        $size: 20px;
                        width: $size;
                        height: $size;
                        background: url('../../public/Images/accordion-min.svg') center center no-repeat;
                        background-size: contain;
                        position: absolute;
                        right: 10px;
                    }
 
                    &.collapsed {
                        .iconx {
                            $size: 20px;
                            width: $size;
                            height: $size;
                            background: url('../../public/Images/accordion-plus.svg') center center no-repeat;
                            background-size: contain;
                            position: absolute;
                            right: 10px;
                        }
                    }
 
 
                }
            }
        }
 
        .collapse {
            .card-body {
                border-top: #ddd solid 1px;
            }
        }
    }
}
// borderRadius: "100%", height: "60px", width: "60px", position: "fixed", bottom: "3%", right: "3%"
.uploadReceiptBtn {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 3%;
    right: 3%;
    z-index: 1;
}

.conditionPage {
    text-align: center;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    &__card {
        width: 550px;
        background: #d2f3d2;
        padding: 50px 30px;
        border-radius: 10px;
        text-align: center;
        h4 {
            font-size: 20px;
            color: green;
        }
    }
}